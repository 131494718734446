<p-toast class="progress-toast" position="top-right" key="progress-toast">
  <ng-template let-message pTemplate="message">
    <div class="w-full">
      <div class="message-container">
        <i class="pi pi-info-circle"></i>
        <span> {{ message.data.title }}</span>
      </div>
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
  </ng-template>
</p-toast>
<p-toast position="top-right" key="error-toast"> </p-toast>
