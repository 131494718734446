import { Component, OnDestroy, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ProgressService } from './progress.service';
import { Subject, takeUntil } from 'rxjs';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'progress-toast',
  templateUrl: './progress-toast.component.html',
  styleUrls: ['./progress-toast.component.scss'],
  imports: [ToastModule, ProgressBarModule],
  standalone: true
})
export class ProgressToastComponent implements OnDestroy {
  private messageService = inject(MessageService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any;
  private destroy$ = new Subject<void>();
  constructor() {
    const progressService = inject(ProgressService);

    progressService.itemCreated
      .pipe(takeUntil(this.destroy$))
      .subscribe((item) => {
        this.messageService.clear('progress-toast');
        this.messageService.add({
          severity: 'info',
          key: 'progress-toast',
          data: item,
          detail: 'Exporting...',
          closable: false,
          sticky: true
        });
      });

    progressService.itemDone.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.messageService.clear('progress-toast');
    });

    progressService.itemFailed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.messageService.clear('progress-toast');
      this.messageService.add({
        severity: 'error',
        key: 'error-toast',
        detail:
          'Error creating report the report. Please try again or contact the support email.',
        closable: true
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
