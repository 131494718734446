import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
@Injectable()
export class ProductionErrorHandler extends ApplicationinsightsAngularpluginErrorService {
  handleError(error: Error) {
    super.handleError(error);
  }
}
export const environment = {
  production: true,
  enableLogin: true,
  plugins: [],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ProductionErrorHandler
    }
  ]
};
