import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgressService {
  itemCreated: Subject<ProgressItem> = new Subject<ProgressItem>();
  itemDone: Subject<ProgressItem> = new Subject<ProgressItem>();
  itemFailed: Subject<ProgressItem> = new Subject<ProgressItem>();
  add(title: string) {
    const item = { title: title };
    this.itemCreated.next(item);
    return item;
  }

  complete(item: ProgressItem) {
    this.itemDone.next(item);
  }

  error(item: ProgressItem) {
    this.itemFailed.next(item);
  }
}

class ProgressItem {
  constructor(public title: string) {}
}
